import React from 'react';
import { DocumentMetadata } from '../shared';

interface GraphViewProps {
    documentData: Array<DocumentMetadata>;
}

const GraphView: React.FC<GraphViewProps> = ({ documentData }) => {
    return (
        <div>
            <h1>TODO: Graph View</h1>
        </div>
    )
}

export default GraphView;