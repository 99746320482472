export interface GraphData {
    id: string;
    name: string;
}

export interface TagData {
    id: string;
    name: string;
}

export interface DocumentMetadata {
    id: string;
    name: string;
    tags: Array<TagData>;
    dateLastEdited: Date;
}

export interface Document {
    id: string;
    name: string;
}

export enum HighlightDirection {
    Left,
    Right,
}