import { v4 } from 'uuid';
import { ROOT_ID, BlockData, BlockMap } from '../shared/block.interface';

/*
 * Functions to make sure the indices and levels of each block are correct
 */
const updateLevelsAndIndicesHelper = (blockMap: BlockMap, blockId: string) => {
    const { level } = blockMap[blockId];
    blockMap[blockId].children.forEach((childId: string, index: number) => {
        blockMap[childId].index = index;
        blockMap[childId].level = level + 1;
        updateLevelsAndIndicesHelper(blockMap, childId);
    });
}

const updateLevelsAndIndices = (blockMap: BlockMap, blockId: string) => {
    updateLevelsAndIndicesHelper(blockMap, blockId);
}

/*
 * Add a child block before all other children
 */
const createAndInsertAfter = (blockMap: BlockMap, blockId: string, content: string = ""): string => {
    let block = blockMap[blockId];
    let { id, parentId, children, level, index } = block;

    let newBlock: BlockData = {
        id: v4(),
        parentId: null,
        content: content,
        level: 0,
        index: 0,
        children: [],
    }
    
    if (children.length > 0) {
        // Add a new block as the first child of this block
        newBlock.parentId = id;
        newBlock.level = level + 1;
        newBlock.index = 0;

        blockMap[newBlock.id] = newBlock;

        blockMap[id].children = [
            newBlock.id,
            ...blockMap[id].children,
        ]

        updateLevelsAndIndices(blockMap, ROOT_ID);
        const nextActiveBlockId = blockMap[id].children[0];
        return nextActiveBlockId;
    } else {
        // Add a new block immediately after this block (on the same level)
        newBlock.parentId = parentId;
        newBlock.level = level;
        newBlock.index = index + 1;

        blockMap[newBlock.id] = newBlock;
        
        blockMap[parentId].children = [
            ...blockMap[parentId].children.slice(0, index + 1),
            newBlock.id,
            ...blockMap[parentId].children.slice(index + 1),
        ]

        updateLevelsAndIndices(blockMap, ROOT_ID);
        const nextActiveBlockId = blockMap[parentId].children[index + 1];
        return nextActiveBlockId;
    }
}

/*
 * Move the cursor's focus up by one block and optionally append a string
 */
const moveCursorUp = (blockMap: BlockMap, blockId: string, appendStr: String = ""): string | null => {
    const { index, level, parentId } = blockMap[blockId];
    const parent = blockMap[parentId];
    
    if (index > 0) { // If there's another block above this block (on the same level)
        let nextBlockId = parent.children[index - 1];
        let nextBlockChildIds = blockMap[nextBlockId].children
        while (nextBlockChildIds.length > 0) {
            nextBlockId = nextBlockChildIds[nextBlockChildIds.length - 1];
            nextBlockChildIds = blockMap[nextBlockId].children;
        }
        blockMap[nextBlockId].content += appendStr;
        return nextBlockId
    } else if (level !== 1) { // Otherwise, if this block has a parent
        blockMap[parentId].content += appendStr;
        return parentId
    }

    // If this is the very first block in the document
    return null
}

/*
 * Move the cursor down by one block
 */
const moveCursorDown = (blockMap: BlockMap, blockId: string): string => {
    const { children } = blockMap[blockId];
    if (children.length > 0) {  // If this block has children, move to its first child
        const firstChildId = children[0];
        return firstChildId;
    } else {
        let currBlockId = blockId;
        let currBlock = blockMap[currBlockId];
        while (currBlock.level >= 1) {
            let currBlockParent = blockMap[currBlock.parentId];
            if (currBlock.index < currBlockParent.children.length - 1) { // If this isn't the last child of its parent
                const nextBlockId = currBlockParent.children[currBlock.index + 1];
                const nextBlock = blockMap[nextBlockId];
                return nextBlock.id;
                break;
            }
            currBlockId = currBlockParent.id
            currBlock = blockMap[currBlockId];
        }
    }
}

/*
 * Indent a block by one level
 */
const indentBlock = (blockMap: BlockMap, blockId: string) => {
    let block = blockMap[blockId];
    if (block.index === 0) { return; }
    
    // Remove block from initial parent
    const currParent = blockMap[block.parentId];
    currParent.children.splice(block.index, 1)

    // Add block to new parent
    let newParentId = currParent.children[block.index - 1];
    blockMap[blockId].parentId = newParentId;
    blockMap[newParentId].children.push(blockId)

    updateLevelsAndIndices(blockMap, ROOT_ID);
}

/*
 * Unindent a block by one level
 */
const unindentBlock = (blockMap: BlockMap, blockId: string) => {
    let block = blockMap[blockId];
    if (block.level === 1) { return; }

    // Remove block from initial parent
    const currParent = blockMap[block.parentId];
    currParent.children.splice(block.index, 1);

    // Add block to new parent
    let newParentId = currParent.parentId;
    blockMap[blockId].parentId = newParentId;
    let newParent = blockMap[newParentId];
    const beforeIdxs = newParent.children.slice(0, currParent.index + 1);
    const afterIdxs = newParent.children.slice(currParent.index + 1, newParent.children.length)
    newParent.children = [...beforeIdxs, blockId, ...afterIdxs]

    updateLevelsAndIndices(blockMap, ROOT_ID);
}

/* 
 * Remove a block from the document
 */
const removeBlock = (blockMap: BlockMap, blockId: string): string => {
    const block = blockMap[blockId];
    const { id, parentId, children, index, level } = block;
    let parent = blockMap[parentId];
    
    if (children.length > 0) { return blockId; }
    if (level === 1 && parent.children.length === 1) {
        // Don't delete the only block left in a document
        return blockId;
    }

    let nextBlockId = null;
    if (level === 1 && index === 0) {
        nextBlockId = moveCursorDown(blockMap, id);
    } else {
        nextBlockId = moveCursorUp(blockMap, id, block.content);
    }

    parent.children = [...parent.children.filter((blockId: string) => blockId !== id)];
    delete blockMap[blockId];
    updateLevelsAndIndices(blockMap, ROOT_ID);

    return nextBlockId;
}

export {
    createAndInsertAfter,
    removeBlock,
    moveCursorUp,
    moveCursorDown,
    indentBlock,
    unindentBlock,
};