import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import { BrowserRouter as Router } from 'react-router-dom';

// Update AWS config with correct auth redirect URLs
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

const [localRedirectSignIn, prodRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(",")
const [localRedirectSignOut, prodRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(",")

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : prodRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : prodRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
