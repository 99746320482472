import React from 'react';
import { Container } from 'react-bootstrap';
import Documents from './Documents';
import DeckOverview from './DeckOverview';
import { GraphDetailProps } from '../shared/props.interface';


const GraphDetails: React.FC<GraphDetailProps> = ({ match: { params: { graphId } }}) => {
    return (
        <Container style={{ marginTop: 30 }}>
            <Documents graphId={graphId}/>
            <DeckOverview graphId={graphId}/>
        </Container>
    )
}

export default GraphDetails;