import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { HighlightDirection } from '../shared/document.interface';
import { BlockMap } from '../shared/block.interface';

interface DocumentContextInterface {
    graphId: string;
    documentId: string;
    title: string;
    setTitle: Dispatch<SetStateAction<string>>;
    blockMap: BlockMap;
    setBlockMap: Dispatch<SetStateAction<BlockMap>>;
    titleActive: boolean;
    setTitleActive: Dispatch<SetStateAction<boolean>>;
    activeBlockId: string | null;
    updateActiveBlockId: (blockId: string | null) => void;
    caretPos: number;
    setCaretPos: Dispatch<SetStateAction<number>>;
    caretOffset: number;
    setCaretOffset: Dispatch<SetStateAction<number>>;
    highlightDirection: HighlightDirection;
    setHighlightDirection: Dispatch<SetStateAction<HighlightDirection>>;
};

export const DocumentContext = createContext<DocumentContextInterface>({
    graphId: "",
    documentId: "",
    title: "",
    setTitle: () => {},
    blockMap: {},
    setBlockMap: () => {},
    titleActive: false,
    setTitleActive: () => {},
    activeBlockId: null,
    updateActiveBlockId: (blockId: string | null) => {},
    caretPos: 0,
    setCaretPos: () => {},
    caretOffset: 0,
    setCaretOffset: () => {},
    highlightDirection: HighlightDirection.Right,
    setHighlightDirection: () => {},
});

export function useDocumentContext() {
    return useContext(DocumentContext);
};