import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createGraph } from '../graphql/mutations';
import { useHistory } from 'react-router-dom';
import { Container, Card, Form, Button } from 'react-bootstrap';

const nameIsValid = (name: string) => {
    return name.length > 0;
}

const NewGraph = () => {
    const history = useHistory();
    const [graphName, setGraphName] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGraphName(event.target.value);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!nameIsValid(graphName)) { return; }

        const createGraphInput = {
            name: graphName,
        };

        // Create Graph Here
        try {
            await API.graphql(graphqlOperation(createGraph, { input: createGraphInput }));
        } catch (err) {
            console.error("Error creating graph:", err);
        }
        

        history.push("/");
    }

    return <Container style={{ marginTop: 30 }}>
        <Card>
            <Card.Header>Create New Graph</Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>Graph Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={graphName}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Button
                        type="submit"
                        disabled={!nameIsValid(graphName)}
                        variant="outline-dark"
                    >
                        Create Graph
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    </Container>
}

export default NewGraph;