import { createContext, useContext, Dispatch, SetStateAction } from 'react';

interface ContextInterface {
    user: any,
    showSyncedStatus: boolean,
    setShowSyncedStatus: Dispatch<SetStateAction<boolean>>,
    synced: boolean,
    setSynced: Dispatch<SetStateAction<boolean>>,
    showAuthButton: boolean,
    setShowAuthButton: Dispatch<SetStateAction<boolean>>,
}

export const AppContext = createContext<ContextInterface>({
    user: null,
    showSyncedStatus: false,
    setShowSyncedStatus: () => {},
    synced: true,
    setSynced: () => {},
    showAuthButton: true,
    setShowAuthButton: () => {},
});

export function useAppContext() {
    return useContext(AppContext);
}