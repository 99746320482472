import React from 'react';
import { Auth } from 'aws-amplify';
import { Button } from 'react-bootstrap';
// import Background from ``

const Landing = () => {
    return (
        <div style={{
            margin: 0,
            backgroundImage: `url(background.jpg)`,
            backgroundSize: 'cover',
            width: '100%',
            height: '100%',
            flex: "1 1 auto",
            padding: 30,
        }}>
            <h1 style={{color: 'white', fontWeight: 300}}>Welcome to Altera Mente</h1>
            <Button variant="outline-light" onClick={() => Auth.federatedSignIn()}>Sign Up or Login</Button>
        </div>
    )
}

export default Landing;