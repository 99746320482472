import React from 'react';
import { Container } from 'react-bootstrap';
import GraphList from './GraphList';

const Home = () => {
    return <Container style={{ marginTop: 30 }}>
        <GraphList/>
    </Container>
}

export default Home;