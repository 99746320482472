import React, { useEffect, useRef } from 'react';
import { useAppContext } from '../Context';
import { useDocumentContext } from './DocumentContext';
import { DocumentTitleProps } from '../shared/props.interface';
import { updateTitle } from './documentApiHelpers';

const DocumentTitle: React.FC<DocumentTitleProps> = ({ documentId, handleTitleKeyDown }) => {
    const { setSynced } = useAppContext();
    const { title, setTitle, titleActive, setTitleActive } = useDocumentContext();

    const titleRef = useRef(null);

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        setSynced(false);
    }

    let titleElement: JSX.Element = titleActive ? (
        <input
            key="documentTitle"
            autoComplete="off"
            type="text"
            onChange={handleTitleChange}
            value={title}
            onBlur={() => updateTitle(documentId, title)}
            onKeyDown={handleTitleKeyDown}
            ref={(element) => {
                if (element) {
                    element.focus();
                    element.setSelectionRange(title.length, title.length);
                }
            }}
            style={{
                border: '0px solid transparent',
                outline: 'none',
                background: 'none',
                width: '100%',
                fontSize: '2.5rem',
                display: titleActive ? "block" : "none",
            }}
        />
    ) : (
        <h1
            onClick={() => {setTitleActive(true)}}
            style={{display: titleActive ? "none" : "block"}}
        >{title}</h1>
    )
    
    return (<div className="documentTitle" style={{marginTop: 20, marginBottom: 20, padding: 4}}>
        {titleElement}
    </div>);
};

export default DocumentTitle;