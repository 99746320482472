import React, { useState, Dispatch, SetStateAction } from 'react';
import { Overlay, Popover, Button, Form } from 'react-bootstrap';
import { useDocumentContext } from '../DocumentContext';
import { BlockData } from '../../shared/block.interface';
import { v4 } from 'uuid';
import NewCardForm from './NewCardForm';

interface NewExtensionPopoverProps {
    extensionType: string;
    show: boolean;
    target: any;
    popoverRef: React.MutableRefObject<any>;
    setShow: Dispatch<SetStateAction<boolean>>;
}

const NewExtensionPopover: React.FC<NewExtensionPopoverProps> = ({
    extensionType, show, target, popoverRef, setShow
}) => {
    
    const {
        graphId, documentId,
        blockMap, setBlockMap,
        caretOffset, setCaretOffset,
        caretPos, activeBlockId,
    } = useDocumentContext();

    

    let block: BlockData = null;
    let blockText = "";
    if (activeBlockId !== null) {
        block = blockMap[activeBlockId];
        blockText = block.content.substring(caretPos, caretPos + caretOffset);
    }

    const createExtensionText = async (data: {[key: string]: any}) => {
        const inlineExtensionData: {[key: string]: string} = {
            id: v4().split("-")[0],
            text: blockText,
            ...data
        };

        const beforeStr = block.content.substring(0, caretPos);
        const cardStr = `\\${extensionType}[${JSON.stringify(inlineExtensionData)}]{${blockText}}`
        const afterStr = block.content.substring(caretPos + caretOffset, block.content.length);
        const newContent = `${beforeStr}${cardStr}${afterStr}`

        blockMap[activeBlockId].content = newContent;
        setBlockMap({...blockMap});
        setCaretOffset(cardStr.length);
        setShow(false);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { key } = event;
        if (key === "Escape") {
            event.preventDefault();
            event.stopPropagation();
            setShow(false);
            setBlockMap({...blockMap});
        }
    }

    let content: JSX.Element = null;
    if (extensionType === "card") {
        content = <NewCardForm
            blockText={blockText}
            createExtensionText={createExtensionText}
        />
    }

    return <Overlay
        show={show}
        target={target}
        placement="top-start"
        container={popoverRef.current}
        containerPadding={20}
    >
        <Popover id="popover-contained"
            style={{width: 1000}}
            onClick={(event) => { event.stopPropagation() }}
            onKeyDown={handleKeyDown}
        >
            <Popover.Content>
                {content}
            </Popover.Content>
        </Popover>
    </Overlay>
}

export default NewExtensionPopover;