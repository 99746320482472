import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api'
import { createBlock, updateBlock, deleteBlock, deleteExtension, updateExtension, createExtension } from '../graphql/mutations';
import { updateDocumentName } from '../graphql/customMutations';
import { CreateBlockMutation, CreateBlockInput, UpdateBlockInput,
    UpdateBlockMutation, DeleteBlockInput, DeleteBlockMutation,
    UpdateDocumentMutation, DeleteExtensionMutation,
    DeleteExtensionInput, UpdateExtensionInput, UpdateExtensionMutation, CreateExtensionMutation,
    CreateExtensionInput,
    ExtensionsByDocumentQuery,
    ExtensionsByDocumentQueryVariables,
} from '../API';
import { ExtensionData } from '../shared/extension.interface';
import { extensionsByDocument } from '../graphql/queries';


/*
 * Create a New Block
 */
const executeCreateBlock = async (
    documentId: string,
    blockId: string, 
    parentId: string | null,
    index: number,
    content: string
): Promise<boolean> => {
    try {
        const createBlockInput: CreateBlockInput = {
            id: blockId,
            documentId: documentId,
            parentId: parentId,
            index: index,
            content: content,
        }
        
        await API.graphql(graphqlOperation(createBlock, {
            input: createBlockInput
        })) as GraphQLResult<CreateBlockMutation>
    } catch (err) {
        console.error("Failed to create block with error:", err);
        return false;
    }

    return true;
}


/*
 * Update an Existing Block
 */
const executeUpdateBlock = async (
    documentId: string,
    blockId: string,
    parentId: string | null,
    index: number,
    content: string
): Promise<boolean> => {
    try {
        const updateBlockInput: UpdateBlockInput = {
            id: blockId,
            documentId: documentId,
            parentId: parentId,
            content: content,
            index: index,
        };

        await API.graphql(graphqlOperation(updateBlock, {
            input: updateBlockInput
        })) as GraphQLResult<UpdateBlockMutation>;
    } catch (err) {
        console.error("Failed to update block with error:", err)
        return false;
    }

    return true;
}


/*
 * Delete an Existing Block
 */
const executeDeleteBlock = async (
    blockId: string
): Promise<boolean> => {
    try {
        const deleteBlockInput: DeleteBlockInput = {
            id: blockId,
        };

        await API.graphql(graphqlOperation(deleteBlock, {
            input: deleteBlockInput
        })) as GraphQLResult<DeleteBlockMutation>;
    } catch (err) {
        console.error("Failed to delete block with error:", err)
        return false;
    }

    return true;
}

/*
 * Create a New Extension
 */
const executeCreateExtension = async (
    graphId: string,
    documentId: string,
    blockId: string,
    id: string,
    type: string,
    data: string,
): Promise<boolean> => {
    try {
        const createExtensionInput: CreateExtensionInput = {
            graphId,
            documentId,
            blockId,
            id,
            type,
            data,
        };

        await API.graphql(graphqlOperation(createExtension, {
            input: createExtensionInput
        })) as GraphQLResult<CreateExtensionMutation>;
    } catch (err) {
        console.error("Failed to create extension with error:", err)
        return false;
    }

    return true;
}


/*
 * Update an Existing Extension
 */
const executeUpdateExtension = async (
    graphId: string,
    documentId: string,
    blockId: string,
    id: string,
    type: string,
    data: string
): Promise<boolean> => {
    try {
        const updateExtensionInput: UpdateExtensionInput = {
            id,
            graphId,
            documentId,
            blockId,
            type,
            data,
        };

        await API.graphql(graphqlOperation(updateExtension, {
            input: updateExtensionInput
        })) as GraphQLResult<UpdateExtensionMutation>;
    } catch (err) {
        console.error("Failed to update extension with error:", err)
        return false;
    }

    return true;
}


/*
 * Delete an Existing Extension
 */
const executeDeleteExtension = async (
    extensionId: string
): Promise<boolean> => {
    try {
        const deleteExtensionInput: DeleteExtensionInput = {
            id: extensionId,
        };

        await API.graphql(graphqlOperation(deleteExtension, {
            input: deleteExtensionInput
        })) as GraphQLResult<DeleteExtensionMutation>;
    } catch (err) {
        console.error("Failed to delete extension with error:", err)
        return false;
    }

    return true;
}

/*
 * Update the Document's Title
 */
const updateTitle = async (documentId: string, title: string): Promise<boolean> => {
    try {
        await API.graphql(graphqlOperation(updateDocumentName, { input: {
            id: documentId,
            name: title,
        }})) as GraphQLResult<UpdateDocumentMutation>;
    } catch (err) {
        console.error("Failed to update title with error:", err)
        return false;
    }

    return true;
}

/*
 * Get all the Extensions in a Document
 */
const listDocumentExtensions = async (documentId: string): Promise<ExtensionData[]> => {
    let extensions: ExtensionData[] = [];
    let nextToken: string | null = null;

    do {
        let input: ExtensionsByDocumentQueryVariables = {
            documentId,
            nextToken,
        };

        const response = await API.graphql(graphqlOperation(extensionsByDocument,
            input
        )) as GraphQLResult<ExtensionsByDocumentQuery>;

        response.data?.extensionsByDocument.items.forEach(item => {
            extensions.push({
                id: item.id,
                graphId: item.graphId,
                documentId: item.documentId,
                blockId: item.blockId,
                type: item.type,
                data: item.data,
            })
        });
    } while (nextToken !== null);

    return extensions;
}

export {
    executeCreateBlock,
    executeUpdateBlock,
    executeDeleteBlock,
    executeCreateExtension,
    executeUpdateExtension,
    executeDeleteExtension,
    updateTitle,
    listDocumentExtensions,
}