import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { createBlock, createDocument } from '../graphql/mutations';
import { documentsByGraph } from '../graphql/queries';
import { useHistory } from 'react-router-dom';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import ListView from './ListView';
import GraphView from './GraphView';
import { DocumentMetadata } from '../shared'
import { DocumentsByGraphQuery, CreateDocumentInput, CreateDocumentMutation,
    CreateBlockInput, CreateBlockMutation
} from '../API';

enum ViewType { List, Graph }

interface DocumentsProps {
    graphId: string;
}

const Documents: React.FC<DocumentsProps> = ({ graphId }) => {
    const history = useHistory();
    const [viewType, setViewType] = useState(ViewType.List);
    const [documentData, setDocumentData] = useState<Array<DocumentMetadata>>([]);

    const loadDocumentData = async () => {
        try {
            const response = await API.graphql(graphqlOperation(documentsByGraph, { graphId: graphId })) as GraphQLResult<DocumentsByGraphQuery>;
            const documents = response.data?.documentsByGraph.items;
            const documentsData: DocumentMetadata[] = documents.map(document => ({
                id: document.id,
                name: document.name,
                tags: [
                    { id: '1234', name: "ExTag" },
                ],
                dateLastEdited: new Date(document.updatedAt),
            }))
            
            documentsData.sort((a, b) => b.dateLastEdited.getTime() - a.dateLastEdited.getTime());
    
            setDocumentData(documentsData);
        } catch (err) {
            console.error("Failed to Load List of Documents:", err)
        }
    }

    useEffect(() => {
        loadDocumentData();
    }, [])

    let documentView = <ListView documentData={documentData}/>
    if (viewType === ViewType.Graph) {
        documentView = <GraphView documentData={documentData}/>
    }

    const createAndOpenDocument = async () => {
        try {
            const createDocInput: CreateDocumentInput = {
                graphId: graphId,
                name: "Untitled",
            }

            const docResponse = await API.graphql(graphqlOperation(createDocument, {
                input: createDocInput
            })) as GraphQLResult<CreateDocumentMutation>;
            const newDocumentId = docResponse.data.createDocument.id;

            const createBlockInput: CreateBlockInput = {
                documentId: newDocumentId,
                parentId: null,
                index: 0,
                content: "TestContent",
            }

            await API.graphql(graphqlOperation(createBlock, {
                input: createBlockInput
            })) as GraphQLResult<CreateBlockMutation>;

            history.push(`/document/${newDocumentId}`);
        } catch (err) {
            console.error("Failed to Create Document: ", err);
        }
    }

    return (
        <Card>
            <Card.Header style={{ display: 'inline' }}>
                <ButtonGroup style={{ float: 'left' }}>
                    <Button
                        variant={`${ viewType === ViewType.List ? '' : 'outline-' }dark`}
                        onClick={() => setViewType(ViewType.List)}
                    >List View</Button>

                    <Button
                        variant={`${ viewType === ViewType.Graph ? '' : 'outline-' }dark`}
                        onClick={() => setViewType(ViewType.Graph)}
                    >Graph View</Button>
                </ButtonGroup>
            </Card.Header>

            <Card.Body style={{padding: 0}}>
                {documentView}
                <Button
                    variant="outline-dark"
                    style={{margin: 16}}
                    onClick={createAndOpenDocument}
                >
                    Create New Document
                </Button>
            </Card.Body>
        </Card>
    )
}

export default Documents;