// Standard Imports
import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { useAppContext } from '../Context';
import { DocumentContext } from './DocumentContext';
import { DocumentProps } from '../shared/props.interface';
import { ExtensionData, ExtensionMap } from '../shared/extension.interface';
import DocumentTitle from './DocumentTitle';
import Block, { getExtensionInfo } from './Block';

// Interface Imports
import { ROOT_ID, RawBlock, BlockData, BlockMap } from '../shared/block.interface';

// API Imports
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api'
import { blocksByDocument, getDocument } from '../graphql/queries';
import { GetDocumentQuery, BlocksByDocumentQuery, BlocksByDocumentQueryVariables } from '../API';
import {
    executeCreateBlock,
    executeUpdateBlock,
    executeDeleteBlock,
    executeCreateExtension,
    executeUpdateExtension,
    executeDeleteExtension,
    updateTitle,
    listDocumentExtensions,
} from './documentApiHelpers';

// Other Imports
import { useInterval } from '../utils';
import _ from 'lodash';
import './Document.css';
import { HighlightDirection } from '../shared/document.interface';

const defaultRootBlock: BlockData = {
    id: ROOT_ID,
    parentId: null,
    content: "",
    index: 0,
    level: 0,
    children: [],
}


const Document: React.FC<DocumentProps> = ({ match: { params } }) => {
    const { documentId } = params;
    const { setSynced, setShowSyncedStatus, setShowAuthButton } = useAppContext();

    const [graphId, setGraphId] = useState("");
    
    const [titleActive, setTitleActive] = useState(false);
    const [activeBlockId, setActiveBlockId] = useState<string | null>(null);
    
    const [title, setTitle] = useState("");
    const dbTitle = useRef("");
    
    const [blockMap, setBlockMap] = useState<BlockMap>({ [ROOT_ID]: JSON.parse(JSON.stringify(defaultRootBlock)) });
    const dbMap = useRef<BlockMap>({});

    const dbExtensionMap = useRef<ExtensionMap>({});

    const syncInProgress = useRef(false);

    const [caretPos, setCaretPos] = useState(0);
    const [caretOffset, setCaretOffset] = useState(0);
    const [highlightDirection, setHighlightDirection] = useState(HighlightDirection.Right);
    
    useEffect(() => {setBlockMap({...blockMap})}, [activeBlockId]);
    useEffect(() => {setBlockMap({...blockMap})}, [titleActive]);
    useEffect(() => { setBlockMap({...blockMap}) }, [caretPos]);

    /*
     * Load document data on page load
     */
    const loadDocumentData = async () => {
        let blocks: RawBlock[] = [];

        // Get Document Name
        let input = { id: documentId };
        const response = await API.graphql(graphqlOperation(getDocument, input)) as GraphQLResult<GetDocumentQuery>;
        const { name, graphId } = response.data?.getDocument;      
        setTitle(name);
        dbTitle.current = name;
        setGraphId(graphId);
        
        let nextToken: string | null = null;
        do {
            let input: BlocksByDocumentQueryVariables = {
                documentId,
                nextToken
            };

            const response = await API.graphql(graphqlOperation(blocksByDocument, input)) as GraphQLResult<BlocksByDocumentQuery>;

            const blockData = response.data?.blocksByDocument;
            nextToken = blockData.nextToken;
            blocks.push(...blockData.items);
        } while (nextToken !== null);
        
        // Populate blockMap data excluding children
        let blockMap: BlockMap = { [ROOT_ID]: JSON.parse(JSON.stringify(defaultRootBlock)) }
        blocks.forEach(block => {
            let blockId = block.id;
            blockMap[blockId] = {
                id: blockId,
                parentId: (block.parentId === null) ? ROOT_ID : block.parentId,
                content: block.content,
                index: block.index,
                level: 0,
                children: [],
            };
        });
 
        // Populate blockMap children data
        Object.keys(blockMap).forEach(blockId => {
            let { id, parentId } = blockMap[blockId];
            if (id === ROOT_ID) { return; }
            parentId = (parentId === null) ? ROOT_ID : parentId;
            blockMap[parentId].children.push(id);
        })

        // Order children correctly and add populate `level` property
        const populateLevelAndSortChildren = (blockId: string) => {
            let block = blockMap[blockId];
            let { level } = block;

            block.children.sort((id1: string, id2: string) => blockMap[id1].index - blockMap[id2].index);
            
            block.children.forEach((childId: string) => {
                blockMap[childId].level = level + 1;
                populateLevelAndSortChildren(childId);
            });
        }

        const extensions = await listDocumentExtensions(documentId);
        dbExtensionMap.current = {};
        extensions.forEach(extension => {
            dbExtensionMap.current[extension.id] = extension;
        })

        populateLevelAndSortChildren(ROOT_ID);
        setBlockMap({...blockMap});
        dbMap.current = _.cloneDeep(blockMap);

        setTitleActive(true);
    };
    
    useEffect(() => {
        setShowSyncedStatus(true);
        setShowAuthButton(false);
        loadDocumentData();
        return () => {
            setShowSyncedStatus(false);
            setShowAuthButton(true);
        }
    }, []);

    useInterval(() => {
        if (syncInProgress.current) { return; }
        syncInProgress.current = true;
        reconcileDbWithClient();
        syncInProgress.current = false;
    }, 2000)

    const reconcileDbWithClient = async () => {        
        const currBlockMap = _.cloneDeep(blockMap);
        const currDbMap = _.cloneDeep(dbMap.current);
        const currDbExtensionMap = _.cloneDeep(dbExtensionMap.current);

        const clientIds = new Set(Object.keys(currBlockMap));
        const dbIds = new Set(Object.keys(currDbMap));
        
        /*
         * Reconcile Document Content
         */
        const idsToCreate = [...clientIds].filter(blockId => !dbIds.has(blockId));
        const idsToDelete = [...dbIds].filter(blockId => !clientIds.has(blockId));

        const intersectionIds = [...clientIds].filter(id => dbIds.has(id));
        const idsToUpdate = intersectionIds.filter(blockId => {
            let clientBlock = currBlockMap[blockId];
            if (clientBlock.parentId === ROOT_ID) { clientBlock.parentId = null }
            
            let dbBlock = currDbMap[blockId];
            if (dbBlock.parentId === ROOT_ID) { dbBlock.parentId = null }
            
            let sameParentId = (clientBlock.parentId === dbBlock.parentId);
            let sameIndex = (clientBlock.index === dbBlock.index);
            let sameContent = (clientBlock.content === dbBlock.content);
            
            const shouldUpdate = !sameParentId || !sameIndex || !sameContent;
            return shouldUpdate;
        });

        let allSuccessful = true;
        
        idsToCreate.forEach(async (blockId)=> {
            let { parentId, index, content } = currBlockMap[blockId];
            parentId = (parentId === ROOT_ID) ? null : parentId;
            const success = await executeCreateBlock(documentId, blockId, parentId, index, content);
            if (success) {
                currDbMap[blockId] = _.cloneDeep(currBlockMap[blockId]);
            } else {
                allSuccessful = false;
            };
        });
        
        idsToDelete.forEach(async (blockId) => {
            const success = await executeDeleteBlock(blockId);
            if (success) {
                delete currDbMap[blockId];
            } else {
                allSuccessful = false;
            }
        });
        
        idsToUpdate.forEach(async (blockId) => {
            let { parentId, index, content } = currBlockMap[blockId];
            parentId = (parentId === ROOT_ID) ? null : parentId;
            const success = await executeUpdateBlock(documentId, blockId, parentId, index, content);
            if (success) {
                currDbMap[blockId] = _.cloneDeep(currBlockMap[blockId]);
            } else {
                allSuccessful = false;
            };
        });
        
        dbMap.current = currDbMap;
        
        /*
         * Reconcile Document Title
         */
        if (title !== dbTitle.current) {
            const success = await updateTitle(documentId, title);
            if (!success) { allSuccessful = false };
        }

        /*
         * Reconcile Document Extensions
         */
        let extensionMap: ExtensionMap = {};
        clientIds.forEach((blockId: string) => {
            let content = currBlockMap[blockId].content;
            let extensions = getExtensionInfo(content);
            extensions.forEach(extension => {
                extensionMap[extension.id] = {
                    id: extension.id,
                    graphId,
                    documentId,
                    blockId,
                    type: extension.type,
                    data: extension.data,
                };
            })
        });

        const docExtensionIds: string[] = Object.keys(extensionMap);
        const dbExtensionIds: string[] = Object.keys(currDbExtensionMap);

        const extensionIdsToCreate = docExtensionIds.filter(extensionId => !currDbExtensionMap.hasOwnProperty(extensionId));
        const extensionIdsToDelete = dbExtensionIds.filter(extensionId => !extensionMap.hasOwnProperty(extensionId));

        const intersectionExtensionIds = docExtensionIds.filter(extensionId => currDbExtensionMap.hasOwnProperty(extensionId));
        const extensionIdsToUpdate = intersectionExtensionIds.filter(extensionId => {
            const clientExtension = extensionMap[extensionId];
            const dbExtension = currDbExtensionMap[extensionId];
            
            const shouldUpdate = !_.isEqual(clientExtension, dbExtension)
            return shouldUpdate;
        });

        extensionIdsToCreate.forEach(async (extensionId) => {
            const { blockId, id, type, data } = extensionMap[extensionId];
            const success = await executeCreateExtension(graphId, documentId, blockId, id, type, data);
            if (success) {
                currDbExtensionMap[extensionId] = extensionMap[extensionId];
            } else { allSuccessful = false; }
        });

        extensionIdsToDelete.forEach(async (extensionId) => {
            const success = await executeDeleteExtension(extensionId);
            if (success) {
                delete currDbExtensionMap[extensionId];
            } else { allSuccessful = false; }
        });

        extensionIdsToUpdate.forEach(async (extensionId) => {
            const { blockId, id, type, data } = extensionMap[extensionId];
            const success = await executeUpdateExtension(graphId, documentId, blockId, id, type, data);
            if (success) {
                currDbExtensionMap[extensionId] = extensionMap[extensionId];
            } else { allSuccessful = false; }
        });

        dbExtensionMap.current = currDbExtensionMap;

        if (allSuccessful) {
            setSynced(true);
        }
    }

    const handleTitleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        
        if (key === "Enter" || key === "ArrowDown") {
            event.preventDefault();
            setTitleActive(false);
            const success = await updateTitle(documentId, title);
            if (success) {

            }

            const firstBlockId = blockMap[ROOT_ID].children[0];
            updateActiveBlockId(firstBlockId);
        }
    }

    /*
     * Update the Block ID set to active and set cursor position accordingly
     */ 
    const updateActiveBlockId = (blockId: string | null) => {
        setActiveBlockId(blockId);

        if (blockId !== null) {
            setCaretPos(blockMap[blockId].content.length);
            setCaretOffset(0);
        }
    }

    /*
     * Construct the JSX element to display document content
     */
    const DocumentBody = () => {
        return <React.Fragment>
            {blockMap[ROOT_ID].children.map((blockId: string) => (
                <Block key={blockId} blockId={blockId}/>
            ))}
        </React.Fragment>
    }

    /*
     * Render the documents
     */
    return <DocumentContext.Provider value={{
        graphId, documentId,
        title, setTitle,
        blockMap, setBlockMap,
        titleActive, setTitleActive,
        activeBlockId, updateActiveBlockId,
        caretPos, setCaretPos,
        caretOffset, setCaretOffset,
        highlightDirection, setHighlightDirection,
    }}>
        <Container
            onClick={() => updateActiveBlockId(null)}
            style={{width: '100%', height: '100%', paddingTop: 24, maxWidth: 700}}
        >
            <DocumentTitle documentId={documentId} handleTitleKeyDown={handleTitleKeyDown}/>
            <DocumentBody/>
        </Container>
    </DocumentContext.Provider>;
};

export default Document;