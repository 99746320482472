import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api'
import { listGraphs } from '../graphql/queries';
import { ListGraphsQuery } from '../API';
import { useHistory } from 'react-router-dom';
import { Card, Table, Button } from 'react-bootstrap';
import { GraphData } from '../shared';


const GraphList = () => {
    const history = useHistory();

    const [graphs, setGraphs] = useState<GraphData[]>([])

    const loadGraphList = async () => {
        try {
            const response = await API.graphql(graphqlOperation(listGraphs)) as GraphQLResult<ListGraphsQuery>;
            const graphs: GraphData[] = response.data?.listGraphs.items.map(item => ({
                id: item.id,
                name: item.name,
            }));

            setGraphs(graphs);
        } catch (err) {
            console.log("Error listing graphs:", err);
        }
    }

    useEffect(() => {
        // Load graph data
        loadGraphList();
    }, [])

    const handleClick = (graphId: string) => {
        history.push(`/graph/${graphId}`);
    }

    const goToNewGraphForm = () => {
        history.push("/newgraph");
    }

    if (graphs.length === 0) {
        return <Card>
            <Card.Header style={{fontWeight: 600}}>My Graphs</Card.Header>
            <Card.Body>
                <p>It doesn't look like you've created any graphs yet.</p>

                {/* Button to Navigate To New Graph Form */}
                <Button variant="outline-dark" onClick={goToNewGraphForm}>Create a New Graph</Button>
            </Card.Body>
        </Card>
    }

    const tableContent: JSX.Element[] = graphs.map(graph => (
        <tr
            key={graph.id}
            onClick={() => handleClick(graph.id)}
            style={{cursor: "pointer"}}
        >
            <td>{graph.name}</td>
        </tr>
    ));

    return <Card>
        <Card.Header style={{fontWeight: 600}}>My Graphs</Card.Header>
        <Card.Body style={{padding: 0}}>
            {/* Table Containing List of Graphs */}
            <Table hover style={{marginBottom: 0, padding: 12}}>
                <thead>
                    <tr>
                        <th style={{ fontWeight: 600 }}>Graph Name</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </Table>

            {/* Button to Navigate To New Graph Form */}
            <Button
                variant="outline-dark"
                style={{margin: 16}}
                onClick={goToNewGraphForm}
            >
                Create a New Graph
            </Button>
        </Card.Body>
    </Card>
}

export default GraphList;