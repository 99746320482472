/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGraph = /* GraphQL */ `
  mutation CreateGraph(
    $input: CreateGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    createGraph(input: $input, condition: $condition) {
      id
      name
      documents {
        items {
          id
          graphId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGraph = /* GraphQL */ `
  mutation UpdateGraph(
    $input: UpdateGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    updateGraph(input: $input, condition: $condition) {
      id
      name
      documents {
        items {
          id
          graphId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGraph = /* GraphQL */ `
  mutation DeleteGraph(
    $input: DeleteGraphInput!
    $condition: ModelGraphConditionInput
  ) {
    deleteGraph(input: $input, condition: $condition) {
      id
      name
      documents {
        items {
          id
          graphId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      graphId
      name
      blocks {
        items {
          id
          documentId
          parentId
          index
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      graphId
      name
      blocks {
        items {
          id
          documentId
          parentId
          index
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      graphId
      name
      blocks {
        items {
          id
          documentId
          parentId
          index
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBlock = /* GraphQL */ `
  mutation CreateBlock(
    $input: CreateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    createBlock(input: $input, condition: $condition) {
      id
      documentId
      parentId
      index
      content
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBlock = /* GraphQL */ `
  mutation UpdateBlock(
    $input: UpdateBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    updateBlock(input: $input, condition: $condition) {
      id
      documentId
      parentId
      index
      content
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBlock = /* GraphQL */ `
  mutation DeleteBlock(
    $input: DeleteBlockInput!
    $condition: ModelBlockConditionInput
  ) {
    deleteBlock(input: $input, condition: $condition) {
      id
      documentId
      parentId
      index
      content
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExtension = /* GraphQL */ `
  mutation CreateExtension(
    $input: CreateExtensionInput!
    $condition: ModelExtensionConditionInput
  ) {
    createExtension(input: $input, condition: $condition) {
      id
      graphId
      documentId
      blockId
      type
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExtension = /* GraphQL */ `
  mutation UpdateExtension(
    $input: UpdateExtensionInput!
    $condition: ModelExtensionConditionInput
  ) {
    updateExtension(input: $input, condition: $condition) {
      id
      graphId
      documentId
      blockId
      type
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExtension = /* GraphQL */ `
  mutation DeleteExtension(
    $input: DeleteExtensionInput!
    $condition: ModelExtensionConditionInput
  ) {
    deleteExtension(input: $input, condition: $condition) {
      id
      graphId
      documentId
      blockId
      type
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
