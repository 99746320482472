import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAppContext } from '../Context';
import Landing from '../Landing';
import Home from '../Home';
import GraphDetails from '../GraphDetails';
import Document from '../Document';
import NewGraph from '../NewGraph';

const Routes = () => {
    const { user } = useAppContext();

    if (!user) {
        // Go to the home page if no user is logged in
        return <Route path="/" component={Landing}/>
    }

    return (
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/newgraph" component={NewGraph}/>
            <Route exact path="/graph/:graphId" component={GraphDetails}/>
            <Route exact path="/document/:documentId" component={Document}/>
        </Switch>
    )
}

export default Routes;