import React from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { DocumentMetadata } from '../shared';
import './ListView.css';

interface ListViewProps {
    documentData: Array<DocumentMetadata>;
}

const formatDate = (date: Date) => {
    const timeDiffSeconds = (new Date().getTime() - date.getTime()) / 1000
    
    if (timeDiffSeconds < 60 * 2) {
        // Less than 2 mins ago
        return "Just now"
    } else if (timeDiffSeconds < 60 * 60) {
        // Less than an hour ago
        return `${Math.floor(timeDiffSeconds / 60)} minutes ago`
    } else if (timeDiffSeconds < 60 * 60 * 24) {
        // Less than a day ago
        return `${Math.floor(timeDiffSeconds / (60 * 60))} hours ago`
    } else if (timeDiffSeconds < 60 * 60 * 24 * 7) {
        // Less than a week ago
        const addS = timeDiffSeconds < 60 * 60 * 24 * 2 ? '' : 's'
        return `${Math.floor(timeDiffSeconds / (60 * 60 * 24))} day${addS} ago`
    }

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
}

const ListView: React.FC<ListViewProps> = ({ documentData }) => {
    const history = useHistory();

    const handleDocClick = (id: string) => {
        history.push(`/document/${id}`);
    }

    const handleTagClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: string) => {
        event.stopPropagation()
    }

    const tableContent = documentData.map(document => (
        <tr
            key={document.id}
            onClick={() => handleDocClick(document.id)}
            style={{cursor: 'pointer'}}
        >
            <td>{document.name}</td>
            <td>{document.tags.map(tag => 
                <span
                    key={tag.id}
                    className="tag"
                    style={{padding: '4px 6px', marginRight: 4, borderRadius: 4, textDecoration: 'none'}}
                    onClick={(event) => handleTagClick(event, tag.id)}
                >
                        #{tag.name}
                </span>
            )}</td>
            <td>{formatDate(document.dateLastEdited)}</td>
        </tr>
    ))

    return (
        <Table hover style={{marginBottom: 0, padding: 12}}>
            <thead>
                <tr >
                    <th style={{ fontWeight: 600 }}>Document Name</th>
                    <th style={{ fontWeight: 600 }}>Tags</th>
                    <th style={{ fontWeight: 600 }}>Date Last Edited</th>
                </tr>
            </thead>
            <tbody>
                {tableContent}
            </tbody>
        </Table>
    )
}

export default ListView;