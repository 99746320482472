export const ROOT_ID = "ROOT"

/*
 * Interface for block data read from the GraphQL database
 */
export interface RawBlock {
    __typename: "Block";
    id: string;
    documentId: string;
    parentId: string;
    index: number;
    content: string;
    createdAt: string;
    updatedAt: string;
    owner: string;
}

/*
 * Interface for storing a single document
 */
export interface BlockData {
    id: string;
    parentId: string | null;
    content: string;
    index: number;
    level: number;
    children: Array<string>;
}

/*
 * Interface for storing all blocks in a document
 */
export interface BlockMap {
    [key: string]: BlockData;
}