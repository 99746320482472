export const updateDocumentName = /* GraphQL */ `
  mutation UpdateDocumentName(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      name
    }
  }
`;