import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { AppContext } from './Context';
import Routes from './Routes';
import NavBar from './NavBar';
import './App.css'

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then(userData => userData)
    .catch(() => console.log("Not signed in"));
}

function App() {
  const [user, setUser] = useState(null);
  const [synced, setSynced] = useState(false);
  const [showSyncedStatus, setShowSyncedStatus] = useState(false);
  const [showAuthButton, setShowAuthButton] = useState(true);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => setUser(userData))
  }, [])

  return (
    <AppContext.Provider value={{ user, synced, setSynced, showSyncedStatus, setShowSyncedStatus, showAuthButton, setShowAuthButton }}>
      <NavBar/>
      <Routes/>
    </AppContext.Provider>
  );
}

export default App;
