import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

interface NewCardFormProps {
    blockText: string;
    createExtensionText: (data: {[key: string]: any}) => Promise<void>;
}

const NewCardForm: React.FC<NewCardFormProps> = ({ blockText, createExtensionText }) => {
    const [question, setQuestion] = useState("");

    const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuestion(event.target.value);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        createExtensionText({
            question: question,
        });
    }

    return <Form onSubmit={handleSubmit}>
        <Form.Label style={{fontWeight: 600}}>Question:</Form.Label><br/>
        <input
            value={question}
            onChange={handleQuestionChange}
            style={{width: '100%', border: '1px solid grey', borderRadius: 2, padding: 5}}
            ref={element => element && element.focus()}
        />
        <p style={{fontWeight: 600, marginRight: 5, marginTop: 5, marginBottom: 0}}>Answer:</p>
        <p style={{marginBottom: 5}}>{blockText}</p>
        <Button
            type="submit"
            variant="outline-dark"
            style={{width: "100%"}}
        >Create Card</Button>
    </Form>
}

export default NewCardForm;