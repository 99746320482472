/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGraph = /* GraphQL */ `
  query GetGraph($id: ID!) {
    getGraph(id: $id) {
      id
      name
      documents {
        items {
          id
          graphId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGraphs = /* GraphQL */ `
  query ListGraphs(
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        documents {
          nextToken
        }
        extensions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      graphId
      name
      blocks {
        items {
          id
          documentId
          parentId
          index
          content
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        graphId
        name
        blocks {
          nextToken
        }
        extensions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBlock = /* GraphQL */ `
  query GetBlock($id: ID!) {
    getBlock(id: $id) {
      id
      documentId
      parentId
      index
      content
      extensions {
        items {
          id
          graphId
          documentId
          blockId
          type
          data
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBlocks = /* GraphQL */ `
  query ListBlocks(
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlocks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        documentId
        parentId
        index
        content
        extensions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExtension = /* GraphQL */ `
  query GetExtension($id: ID!) {
    getExtension(id: $id) {
      id
      graphId
      documentId
      blockId
      type
      data
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExtensions = /* GraphQL */ `
  query ListExtensions(
    $filter: ModelExtensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtensions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        graphId
        documentId
        blockId
        type
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const documentsByGraph = /* GraphQL */ `
  query DocumentsByGraph(
    $graphId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByGraph(
      graphId: $graphId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        graphId
        name
        blocks {
          nextToken
        }
        extensions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const blocksByDocument = /* GraphQL */ `
  query BlocksByDocument(
    $documentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelBlockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blocksByDocument(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        parentId
        index
        content
        extensions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const extensionsByGraph = /* GraphQL */ `
  query ExtensionsByGraph(
    $graphId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelExtensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    extensionsByGraph(
      graphId: $graphId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        graphId
        documentId
        blockId
        type
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const extensionsByDocument = /* GraphQL */ `
  query ExtensionsByDocument(
    $documentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelExtensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    extensionsByDocument(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        graphId
        documentId
        blockId
        type
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const extensionsByBlock = /* GraphQL */ `
  query ExtensionsByBlock(
    $blockId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelExtensionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    extensionsByBlock(
      blockId: $blockId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        graphId
        documentId
        blockId
        type
        data
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
