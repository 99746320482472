import React, { useState, useRef } from 'react'
import { useDocumentContext } from './DocumentContext';
import { useAppContext } from '../Context';
import { BlockProps } from '../shared/props.interface'
import { HighlightDirection } from '../shared/document.interface';
import { ExtensionInfo } from '../shared/extension.interface';
import NewCardPopover from './Extensions/NewExtensionPopover';
import TextareaAutosize, { TextareaHeightChangeMeta} from 'react-textarea-autosize';

import {
    createAndInsertAfter,
    removeBlock,
    moveCursorUp,
    moveCursorDown,
    indentBlock,
    unindentBlock,
} from './blockMapMutations';

// Markdown-Related Imports
import DOMPurify from 'dompurify';
import MarkdownIt from 'markdown-it';
import MarkdownItTexMath from 'markdown-it-texmath';
import Katex from 'katex';
import _ from 'lodash';
import { getElementError } from '@testing-library/react';

const TAB_SIZE = 32;


/*
 * Extension Processing
 */

const extensionRe: RegExp = /\\([A-Za-z]+?)\[(.*?)\]\{(.*?)\}/g;

export const getExtensionInfo = (str: string): ExtensionInfo[] => {
    let results: ExtensionInfo[] = [];
    let latestResult;
    do {
        latestResult = extensionRe.exec(str);
        if (latestResult) {
            let data = latestResult[2];
            results.push({
                id: JSON.parse(data).id,
                wholeString: latestResult[0],
                type: latestResult[1],
                data: data,
                blockText: latestResult[3],
            });
        }
    } while (latestResult);

    return results;
}

/*
 * Markdown Processing
 */
const md = new MarkdownIt().use(MarkdownItTexMath, {
    engine: Katex,
    delimiters: 'dollars',
});

const getMdElement = (markdownStr: string) => {
    let cleanedStr = markdownStr;
    let extensionInfo = getExtensionInfo(cleanedStr);
    extensionInfo.forEach((info: ExtensionInfo) => {
        markdownStr = markdownStr.replace(info.wholeString, info.blockText);
    });

    const cleanedMd = DOMPurify.sanitize(markdownStr);
    const htmlStr = md.render(cleanedMd);
    const element = <div dangerouslySetInnerHTML={{__html: htmlStr}}></div>
    return element;
}

/*
 * Block Component
 */
const Block: React.FC<BlockProps>= ({ blockId }) => {
    const {
        graphId, documentId,
        blockMap, setBlockMap,
        activeBlockId, updateActiveBlockId,
        caretPos, setCaretPos,
        caretOffset, setCaretOffset,
        highlightDirection, setHighlightDirection,
        setTitleActive,
    } = useDocumentContext();

    const { setSynced } = useAppContext();

    const [newExtensionType, setNewExtensionType] = useState("");

    const [showNewCardPopover, setShowNewCardPopover] = useState(false);
    const [blockTarget, setBlockTarget] = useState<EventTarget | null>(null)
    const blockBodyRef = useRef(null);

    /*
     * Handle Key Down Press (this runs before handleUpdate)
     */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>, blockId: string) => {
        const { key, shiftKey, metaKey, altKey, ctrlKey } = event;

        const nativeEvent = event.nativeEvent as KeyboardEvent;
        const nativeTarget = nativeEvent.target as HTMLTextAreaElement;
        const { selectionStart, selectionEnd } = nativeTarget;

        const significantChars = new Set(['"', "'", '[', ']', '{', '}', '(', ')', '`', '$', ' ', '.', ',']);

        const content = _.clone(blockMap[blockId].content);

        if (key === "Enter") {
            event.preventDefault();
            blockMap[blockId].content = content.substring(0, selectionStart);
            const nextActiveId = createAndInsertAfter(blockMap, blockId, content.substring(selectionStart));
            updateActiveBlockId(nextActiveId);
            setCaretPos(0);
            setSynced(false);
        } else if (key === "Home") {
            event.preventDefault()
            setCaretPos(0);
        } else if (key === "End") {
            event.preventDefault()
            setCaretPos(content.length)
        } else if (key === "ArrowLeft") {
            event.preventDefault();
            
            let highlightDir = highlightDirection;
            if (caretOffset === 0 && shiftKey) {
                setHighlightDirection(HighlightDirection.Left);
                highlightDir = HighlightDirection.Left;
            }
            
            let prevCaretOffset = caretOffset;
            if (!shiftKey) { setCaretOffset(0); }

            if (metaKey || ctrlKey) {
                if (shiftKey && highlightDir === HighlightDirection.Left) {
                    setCaretOffset(selectionEnd);
                } else if (shiftKey && highlightDir === HighlightDirection.Right) {
                    setCaretOffset(selectionStart);
                }

                setCaretPos(0);

                setHighlightDirection(HighlightDirection.Left);

                return;
            } else if (altKey) {
                let lastSignificantIdx = selectionStart - 1;
                if (shiftKey && highlightDir === HighlightDirection.Right) {
                    lastSignificantIdx = selectionEnd - 1;
                }

                for (; lastSignificantIdx > 0; lastSignificantIdx--) {
                    if (significantChars.has(content[lastSignificantIdx - 1])) {
                        break;
                    }
                }
                lastSignificantIdx = Math.max(lastSignificantIdx, 0);

                if (shiftKey && highlightDir === HighlightDirection.Left) {
                    setCaretOffset(selectionEnd - lastSignificantIdx);
                    setCaretPos(lastSignificantIdx);
                } else if (shiftKey && highlightDir === HighlightDirection.Right) {
                    if (lastSignificantIdx > selectionStart) {
                        setCaretOffset(lastSignificantIdx - selectionStart);
                    } else {
                        setCaretOffset(selectionStart - lastSignificantIdx);
                        setCaretPos(lastSignificantIdx);
                        setHighlightDirection(HighlightDirection.Left);
                    }
                } else {
                    setCaretPos(lastSignificantIdx);
                }

                return;
            } else if (shiftKey) {
                if (highlightDir === HighlightDirection.Left) {
                    if (caretPos > 0) {
                        setCaretPos(caretPos - 1);
                        setCaretOffset(prevCaretOffset + 1);
                    }
                } else if (prevCaretOffset > 0) {
                    setCaretOffset(prevCaretOffset - 1);
                }
            } else {
                let offset = (prevCaretOffset > 0) ? 0 : -1;
                setCaretPos(Math.max(caretPos + offset, 0));
            }
        } else if (key === "ArrowRight") {
            event.preventDefault();
            
            let highlightDir = highlightDirection;
            if (caretOffset === 0 && shiftKey) {
                setHighlightDirection(HighlightDirection.Right);
                highlightDir = HighlightDirection.Right;
            }

            let prevCaretOffset = caretOffset;
            if (!shiftKey) { setCaretOffset(0); }

            if (metaKey || ctrlKey) {
                if (shiftKey && highlightDir === HighlightDirection.Right) {
                    setCaretOffset(content.length - selectionStart);
                } else if (shiftKey && highlightDir === HighlightDirection.Left) {
                    setCaretPos(selectionEnd);
                    setCaretOffset(content.length - selectionEnd);
                } else { // if !shiftKey
                    setCaretPos(content.length);
                }

                setHighlightDirection(HighlightDirection.Right);

                return;
            } else if (altKey) {
                let nextSignificantIdx = selectionEnd + 1;
                if (shiftKey && highlightDir === HighlightDirection.Left) {
                    nextSignificantIdx = selectionStart + 1;
                }

                for (; nextSignificantIdx < content.length; nextSignificantIdx++) {
                    if (significantChars.has(content[nextSignificantIdx])) {
                        break;
                    }
                }
                nextSignificantIdx = Math.min(nextSignificantIdx, content.length);

                if (shiftKey && highlightDir === HighlightDirection.Right) {
                    setCaretOffset(nextSignificantIdx - selectionStart);
                } else if (shiftKey && highlightDir === HighlightDirection.Left) {
                    if (nextSignificantIdx < selectionEnd) {
                        setCaretPos(nextSignificantIdx);
                        setCaretOffset(selectionEnd - nextSignificantIdx);
                    } else {
                        setCaretPos(selectionEnd);
                        setCaretOffset(nextSignificantIdx - selectionEnd);
                        setHighlightDirection(HighlightDirection.Right);
                    }
                } else { // if !shiftKey
                    setCaretPos(nextSignificantIdx);
                }

                return;
            } else if (shiftKey) {
                if (highlightDir === HighlightDirection.Right) {
                    if (caretPos + prevCaretOffset < content.length) {
                        setCaretOffset(prevCaretOffset + 1);
                    }
                } else if (prevCaretOffset > 0) {
                    setCaretOffset(prevCaretOffset - 1);
                    setCaretPos(caretPos + 1);
                }
            } else {
                let offset = (prevCaretOffset > 0) ? prevCaretOffset : 1;
                setCaretPos(Math.min(caretPos + offset, content.length));
            }
        } else if (key === "ArrowUp") {
            event.preventDefault();
            const nextBlockId = moveCursorUp(blockMap, blockId);
            if (nextBlockId !== undefined) { updateActiveBlockId(nextBlockId); }
            if (blockMap[blockId].level === 1 && blockMap[blockId].index === 0) {
                setTitleActive(true);
                updateActiveBlockId(null);
            }
        } else if (key === "ArrowDown") {
            event.preventDefault();
            const nextBlockId = moveCursorDown(blockMap, blockId);
            if (nextBlockId !== undefined) { updateActiveBlockId(nextBlockId); }
        } else if (key === "Backspace" && selectionStart === 0 && selectionEnd === 0) {
            event.preventDefault()
            const nextBlockId = removeBlock(blockMap, blockId);
            updateActiveBlockId(nextBlockId);
            setSynced(false);
        } else if (shiftKey && key === "Tab") {
            event.preventDefault();
            unindentBlock(blockMap, blockId);
            setBlockMap({...blockMap});
            setSynced(false);
        } else if (key === "Tab") {
            event.preventDefault();
            indentBlock(blockMap, blockId);
            setBlockMap({...blockMap});
            setSynced(false);
        } else if (key === "Escape") {
            setShowNewCardPopover(false);
            updateActiveBlockId(null);
        } else if ((ctrlKey || metaKey) && key === "i") {
            const newContent = content.substring(0, selectionStart) + "_" + content.substring(selectionStart, selectionEnd) + "_" + content.substring(selectionEnd)
            blockMap[blockId].content = newContent;
            setBlockMap({...blockMap});
            setCaretPos(selectionStart + 1);
            setSynced(false);
        } else if ((ctrlKey || metaKey) && key === "b") {
            const newContent = content.substring(0, selectionStart) + "**" + content.substring(selectionStart, selectionEnd) + "**" + content.substring(selectionEnd)
            blockMap[blockId].content = newContent;
            setBlockMap({...blockMap});
            setCaretPos(selectionStart + 2);
            setSynced(false);
        } else if ((ctrlKey || metaKey) && key === "j") {
            let match;
            let overlaps = false;
            do {
                match = extensionRe.exec(content);
                if (match !== null) {
                    let minIdx = match.index;
                    let maxIdx = minIdx + match[0].length;
                    let startsInPattern = minIdx <= selectionStart && selectionStart <= maxIdx;
                    let endsInPattern = minIdx <= selectionEnd && selectionEnd <= maxIdx;
                    let surroundsPattern = selectionStart <= minIdx && maxIdx <= selectionEnd;
                    console.log(minIdx, maxIdx, selectionStart, selectionEnd, startsInPattern, endsInPattern, surroundsPattern);
                    if (startsInPattern || endsInPattern || surroundsPattern) {
                        overlaps = true;
                    }
                }
            } while (match);
            
            if (!overlaps) {
                setShowNewCardPopover(true);
                setBlockTarget(event.target);
                setNewExtensionType("card");
            }
        }
    }

    /*
     * Handle normal updates to the content of the block
     */
    const handleUpdate = (event: React.ChangeEvent<HTMLTextAreaElement>, blockId: string) => {
        let newContent = event.target.value;

        // Get cursor position data
        const { inputType } = event.nativeEvent as InputEvent;
        let { selectionStart } = event.target;

        setCaretOffset(0);

        // Get keys at and around cursor in new content
        const prevKey = newContent[selectionStart - 2];
        const key = newContent[selectionStart - 1];
        const nextKey = newContent[selectionStart];
        const nextNextKey = newContent[selectionStart + 1];

        let block = blockMap[blockId];
        let prevContent = block.content;

        const didAdd = (prevContent.length < newContent.length)
        const didRemove = !didAdd;
        const numRemoved = prevContent.length - newContent.length

        let caretIncrement = didAdd ? 1 : -1

        if (didRemove) {
            caretIncrement = selectionStart - caretPos
        }

        const keysToMatch: { [id: string]: string } = {
            "[": "]",
            "{": "}",
            "(": ")",
            "`": "`",
            "\"": "\"",
        };

        const otherKeyPairs = ["$", "_", "*"]

        const didFinishMatch = Object.keys(keysToMatch).map(k => (prevKey === k) && (key === keysToMatch[k])).some((x) => x);

        if (didRemove && inputType === "insertText" && keysToMatch.hasOwnProperty(key)) {
            // If the user highlighted some text and then pressed one of the keys in keysToMatch
            const oldStr = block.content;
            newContent =  oldStr.substring(0, selectionStart - 1) + key;
            newContent += oldStr.substring(selectionStart - 1, selectionStart + numRemoved);
            newContent += keysToMatch[key] + oldStr.substring(selectionStart + numRemoved);
        } else if (didAdd && keysToMatch.hasOwnProperty(key)) {
            newContent = newContent.substring(0, selectionStart) + keysToMatch[key] + newContent.substring(selectionStart);
        } else if (didAdd && didFinishMatch) {
            newContent = prevContent;
        } else if (numRemoved === 1) {
            const keyRemoved = block.content[selectionStart]
            if (keysToMatch[keyRemoved] === nextKey || (keyRemoved === nextKey && otherKeyPairs.includes(keyRemoved))) {
                newContent = newContent.substring(0, selectionStart) + newContent.substring(selectionStart + 1);
            }
        } else if (key === prevKey && nextNextKey !== prevKey && otherKeyPairs.includes(key)) {
            caretIncrement -= 1;
        }

        block.content = newContent;
        setBlockMap({...blockMap})

        if (didRemove && inputType === "insertText" && keysToMatch.hasOwnProperty(key)) {
            // If attempted to cover a highlighted region of text with an element of the keysToMatch dict
            setCaretPos(selectionStart + numRemoved + 1);
        } else if (Math.abs(selectionStart - caretPos) > 1) {
            setCaretPos(selectionStart);
        } else {
            setCaretPos(caretPos + caretIncrement);
        }

        setSynced(false);
    }

    const block = blockMap[blockId];

    let textAreaElement = <TextareaAutosize
        minRows={1}
        maxRows={Infinity}
        onHeightChange={(height: number, meta: TextareaHeightChangeMeta) => {console.log(height, meta)}}
        autoComplete="off"
        ref={(element: HTMLTextAreaElement) => {
            if (element) {
                element.focus();
                element.setSelectionRange(caretPos, caretPos + caretOffset);
            }
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => handleKeyDown(event, blockId)}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => handleUpdate(event, blockId)}
        value={block.content}
        onClick={(event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => {
            event.stopPropagation();
        }}
        onMouseUp={(event) => {
            const nativeEvent = event.nativeEvent as MouseEvent;
            const nativeTarget = nativeEvent.target as HTMLTextAreaElement;
            const { selectionStart, selectionEnd } = nativeTarget;
            setCaretPos(selectionStart);
            setCaretOffset(selectionEnd - selectionStart);
        }}
        style={{
            border: '0px solid transparent',
            outline: 'none',
            background: 'none',
            width: '100%',
            resize: 'none',
        }}
    ></TextareaAutosize>

    let divElement = <div
        onMouseDown={() => updateActiveBlockId(blockId)}
        style={{width: '100%'}}
    >
        {block.content.length > 0 ? getMdElement(block.content) : " "}
    </div>

    const blockText: JSX.Element = blockId === activeBlockId ? textAreaElement : divElement;

    const blockBody: JSX.Element = <div
        ref={blockBodyRef}
        className={activeBlockId === blockId ? "input-block" : "display-block"}
        style={{ marginLeft: (block.level - 1) * TAB_SIZE, paddingLeft: 6, display: 'flex' }}
    >
        <span style={{paddingRight: 4}}>&bull;</span>
        <span style={{ display: 'flex', paddingLeft: 2, width: '100%' }}>
            {blockText}
        </span>
    </div>

    const blockChildren: JSX.Element[] = blockMap[blockId].children.map((blockId: string) => (
        <Block key={blockId} blockId={blockId}/>
    ))

    const newCardPopover = <NewCardPopover
        extensionType={newExtensionType}
        show={showNewCardPopover}
        popoverRef={blockBodyRef}
        target={blockTarget}
        setShow={setShowNewCardPopover}
    />
    
    return <React.Fragment>
        {blockBody}
        {blockChildren}
        {newCardPopover}
    </React.Fragment>;
}

export default Block;