import React from 'react';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../Context';
import { Navbar, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const NavBar = () => {
    const { user, showSyncedStatus, synced, showAuthButton } = useAppContext();

    let syncStatusIcon: JSX.Element = null;
    if (showSyncedStatus) {
        syncStatusIcon = <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="synced-tooltip">{synced ? "Synced" : "Sync in Progress"}</Tooltip>}
        >
            <div
                style={{
                    width: 10,
                    height: 10,
                    borderRadius: 1000,
                    marginRight: 20,
                    backgroundColor: synced ? 'green' : 'red',
                }}
            ></div>
        </OverlayTrigger>
    }

    let authButton: JSX.Element = null;
    if (showAuthButton) {
        authButton = <Navbar.Text>
            {user ? (
            <Button variant="outline-light" onClick={() => Auth.signOut()}>Sign Out</Button>
            ) : (
            <Button variant="outline-light" onClick={() => Auth.federatedSignIn()}>Sign In</Button>
            )}
        </Navbar.Text>
    }

    return (
        <Navbar bg="dark" variant="dark" style={{flex: "0 1 auto"}}>
            <Navbar.Brand href="/">Altera Mente</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                {syncStatusIcon}
                {authButton}
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;