import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { DeckProps } from '../shared/props.interface';

const DeckOverview: React.FC<DeckProps> = ({ graphId }) => {
    const history = useHistory();

    const handleReviewClick = () => {
        history.push(`/graph/${graphId}/cards`)
    }

    return (
        <Card>
            <Card.Header style={{ display: 'inline', fontWeight: 600 }}>
                My Spaced Repetition Cards
            </Card.Header>

            <Card.Body>
                <p><strong>Cards Awaiting Review:</strong> TODO</p>
                <p><strong>Date Last Reviewed:</strong> TODO</p>
                <Button variant="outline-dark" onClick={handleReviewClick}>Review Cards</Button>
            </Card.Body>
        </Card>
    )
}

export default DeckOverview;